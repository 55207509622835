<template>
	<article class="view-dashboard">
		<div class="site-wrapper rythm-v">
			<header class="rythm-v">
				<h1 v-if="!caseID || !currentCase">Gestion des patients</h1>
				<h1 v-else><strong>Patient {{ currentCase.pid }}</strong> <span v-if="currentCase.desease_data?.disease_type">– {{ currentCase.desease_data.disease_type }}</span></h1>

				<div role="toolbar"
						 aria-label="Actions sur les patients"
						 class="toolbar flex-row flex-align-left flex-center-v card shadow">
					<router-link to="/cases"
											 class="button--outline-inverse">
						<IconUsers class="icon" />
						<span class="caption"
									id="button-new-case-title">Liste des patients</span>
					</router-link>

					<router-link to="/cases/new"
											 class="button--outline-inverse">
						<IconUserPlus class="icon" />
						<span class="caption"
									id="button-new-case-title">Ajouter un patient</span>
					</router-link>

					<!-- TODO: EXPORT -->
					<!-- <a :href="exportLink"
						 class="button--outline-inverse"
						 v-if="userCan('manage_options', user)">
						<IconDownload class="icon" /><span class="caption">Exporter les patients</span>
					</a> -->
				</div>
			</header>

			<div>
				<router-view></router-view>
			</div>
		</div>
	</article>
</template>

<style lang="scss">
@import "~@/scss/config";
@import "~@/scss/06-components/components.card";
</style>

<script>
import { appConfig } from "../config.js";
// import IconDownload from "@/assets/svg/icons/icon-download.svg";
import IconUserPlus from "@/assets/svg/icons/user-plus.svg";
import IconUsers from "@/assets/svg/icons/icon-users.svg";

export default {
	name: "PageDashboard",
	components: {
		// IconDownload, 
		IconUserPlus,
		IconUsers
	},

	computed: {

		caseID() {
			return this.$route.params.caseID;
		},

		currentCase() {
			if (!this.caseID) return null;
			return this.$store.state.caseData;
		},

		exportLink() {
			return `${appConfig.API_BASE_URL}/exports/cases?format=csv`;
		},
	},

	mounted() {
		// Close current case (reset case data)
		this.$router.beforeEach((to, from, next) => {
			if (to.name === "dashboard-list-cases") {
				this.$store.dispatch("CLOSE_CASE");
			}
			next();
		});
	},
};
</script>
